import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

function Brand(props) {
  return (
    <Link to={props.link || '/'} className="inline-block text-black hover:text-indigo-500">
      <ReactSVG src="/img/logo.svg" wrapper="div" beforeInjection={(svg) => svg.classList.add('w-14', 'h-6', 'md:w-18', 'md:h-8', 'fill-current')} />
    </Link>
  );
}

export default Brand;
