import { Transition } from '@headlessui/react'
import { EmojiSadIcon } from '@heroicons/react/outline'
import FormSubmit from './FormSubmit'
import Navigation from './Navigation'

function Auth(props) {

  return (
    <>
      <Navigation items={props.navItems} />
      <main className="relative p-6 w-full min-h-screen flex items-center bg-no-repeat bg-beam bg-bottom bg-cover">
        <section className="w-full">
          <form onSubmit={props.onSubmit} className="mx-auto p-9 md:p-12 lg:p-14 max-w-md bg-gray-50 shadow-xl ring-1 ring-gray-900/5 rounded-lg text-gray-500">
            <h1 className="mb-12 text-lg lg:text-xl font-regular text-center text-gray-700">{props.title}</h1>
            <Transition
              show={Object.keys(props.errors).length > 0}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="mb-9 flex items-center w-full"
            >
              <EmojiSadIcon
                className="flex-shrink-1 w-6 h-6 mr-2 text-rose-500"
                aria-hidden="true"
              />
              <span className="text-rose-500 text-sm font-medium">{props.errors.message}</span>
            </Transition>
            {
              props.fields.map((field, i) => <div key={i} className={field.containerClass}>{field}</div>)
            }
            <div className={props.hasOwnProperty('help') ? 'pt-6 border-t border-gray-100 w-full flex items-center justify-between' : 'pt-6 border-t border-gray-100 w-full'}>
              <div><FormSubmit label={props.submitLabel} loading={props.loading} /></div>
              {props.help}
            </div>
          </form>
        </section>
      </main>
    </>
  );
}

export default Auth;
