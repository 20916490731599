import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Avatar from './Avatar'
import {
  Logout,
  User
} from '../services/user'

function UserDropdown(props) {
  const logoutUser = event => {
    Logout()
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-light text-indigo-500 bg-indigo-900 rounded-md bg-opacity-0 hover:bg-opacity-5 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
          <div className="flex items-center">
            <div className="flex-shrink-0 pr-1">
              <Avatar src="/img/user.png" />
            </div>
            <div>
              {User().first_name}
              <ChevronDownIcon
                className="inline-block w-4 h-4 ml-1 -mr-1 text-pink-400 hover:text-pink-500"
                aria-hidden="true"
              />
            </div>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
      <Menu.Items className="absolute right-0 p-1 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-indigo-500 ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <Link
              className={
                `${
                  active ? 'bg-indigo-500 text-white' : 'text-indigo-500'
                } group flex rounded-md items-center w-full px-2 py-2 text-sm`
              }
              to="/tokens"
            >
            API Tokens
          </Link>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <Link
              className={
                `${
                  active ? 'bg-indigo-500 text-white' : 'text-indigo-500'
                } group flex rounded-md items-center w-full px-2 py-2 text-sm`
              }
              to="/settings"
            >
              Settings
            </Link>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <a
              onClick={logoutUser}
              href="/"
              className={
                `${
                  active ? 'bg-indigo-500 text-white' : 'text-indigo-500'
                } group flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer`
              }
            >
              Logout
            </a>
          )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default UserDropdown;
