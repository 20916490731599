import { useState } from 'react'

export default function useAuth() {
  const getAuth = () => {
    return localStorage.getItem('auth')
  }

  const [auth, setAuth] = useState(getAuth())

  const saveAuth = auth => {
    localStorage.setItem('auth', auth)
    setAuth(auth)
  }

  return {
    setAuth: saveAuth,
    auth
  }
}
