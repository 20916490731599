import React, { useEffect, useState } from 'react'
import {
  Client,
  Balance,
  Sent,
  Received
} from '../services/user'
import Field from '../components/Field'
import HTTP from '../services/http'

import Transactions from '../components/Transactions'

function TransactionList() {
  const [transactions, setTransactions] = useState()
  const [loaded, setLoaded] = useState(false)
  const [errors, setErrors] = useState({})
  const [account, setAccount] = useState('')
  const [amount, setAmount] = useState('')
  const [status, setStatus] = useState('')
  const [memo, setMemo] = useState('')
  const [lte, setLte] = useState('')
  const [gte, setGte] = useState('')
  const [to, setTo] = useState('')
  const [from, setFrom] = useState('')
  const [id, setId] = useState('')
  const [receipt, setReceipt] = useState('')
  const [description, setDescription] = useState('')
  const [csv, setCsv] = useState(0)

  useEffect(() => {
    getTransactions()
  }, [])

  const getTransactions = async () => {
    await HTTP.post('/transactions', {
      account_number: account,
      amount: amount,
      status: status,
      lte: lte,
      gte: gte,
      to: to,
      from: from,
      receipt_id: receipt,
      id: id,
      memo: memo,
      export: csv,
      description: description,
    })
      .then((response) => setTransactions(response.data))
      .catch((error) => handleErrors(error.response.data))

    setLoaded(true)
  }

  const handleErrors = (data) => {
    setErrors(data)
    setLoaded(false)
  }

  const handlSubmit = (event) => {
    event.preventDefault()
    getTransactions()
  }

  return (
    <div>
      <form onSubmit={handlSubmit} className="mb-1 grid grid-cols-5 gap-8 items-end rounded-md p-8 bg-gray-100">
        <div className="col-span-2 flex gap-4">
          <label className="block text-xs">
            <span className="block mb-1 text-gray-500">ID №</span>
            <input onChange={event => setId(event.target.value)} type="number" className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300" placeholder="Ex. 44"/>
          </label>
          <label className="block text-xs">
            <span className="block mb-1 text-gray-500">Receipt №</span>
            <input onChange={event => setReceipt(event.target.value)} type="text" className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300" placeholder="Ex. User#123"/>
          </label>
          <label className="block text-xs">
            <span className="block mb-1 text-gray-500">Account №</span>
            <input onChange={event => setAccount(event.target.value)} type="number" className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300" placeholder="Ex. 0610101010"/>
          </label>
        </div>

        <div className="col-span-2 flex gap-4">
          <label className="block text-xs">
            <span className="block mb-1 text-gray-500">= Amount</span>
            <input onChange={event => setAmount(event.target.value)} type="number" className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300" placeholder="Ex. 10.123"/>
          </label>
          <label className="block text-xs">
            <span className="block mb-1 text-gray-500">≤ Amount</span>
            <input onChange={event => setLte(event.target.value)} type="number" className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300" placeholder="Ex. 10.123"/>
          </label>
          <label className="block text-xs">
            <span className="block mb-1 text-gray-500">≥ Amount</span>
            <input onChange={event => setGte(event.target.value)} type="number" className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300" placeholder="Ex. 10.123"/>
          </label>
        </div>

        <div className="flex gap-4">
          <label className="block w-1/2 text-xs">
            <span className="block mb-1 text-gray-500">Memo</span>
            <select onChange={event => setMemo(event.target.value)} className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300">
              <option value=""></option>
              <option value="CREDIT">Credit</option>
              <option value="DEBIT">Debit</option>
            </select>
          </label>
          <label className="block w-1/2 text-xs">
            <span className="block mb-1 text-gray-500">Status</span>
            <select onChange={event => setStatus(event.target.value)} className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300">
              <option value=""></option>
              <option value="APPROVED">Approved</option>
              <option value="CANCELLED">Cancelled</option>
              <option value="DECLINED">Declined</option>
              <option value="FAILED">Failed</option>
              <option value="PENDING">Pending</option>
            </select>
          </label>
        </div>

        <div className="col-span-2 flex gap-4">
          <label className="block w-1/2 text-xs">
            <span className="block mb-1 text-gray-500">Start Date</span>
            <input onChange={event => setFrom(event.target.value)} type="date" className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300" placeholder="Ex. 10.123"/>
          </label>
          <label className="block w-1/2 text-xs">
            <span className="block mb-1 text-gray-500">End Date</span>
            <input onChange={event => setTo(event.target.value)} type="date" className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300" placeholder="Ex. 10.123"/>
          </label>
        </div>

        <label className="col-span-2 block text-xs">
          <span className="block mb-1 text-gray-500">Description</span>
          <input onChange={event => setDescription(event.target.value)} type="text" className="p-2 block w-full rounded shadow bg-white border-0 border-gray-300 placeholder-gray-300" placeholder="Enter keyword"/>
        </label>

        <div className="flex gap-4 items-center">
          <div className="w-full">
            <label className="inline-flex items-center">
              <input onChange={event => setCsv(event.target.value)} value="1" type="checkbox" className="rounded p-2 shadow-lg bg-white text-indigo-600 border border-gray-300 focus:border-transparent focus:bg-gray-200 focus:ring-1 focus:ring-offset-2 focus:ring-gray-500"/>
              <span className="ml-2 text-xs">Export CSV</span>
            </label>
          </div>
          <button type="submit" className="justify-self-end text-xs py-2 px-4 flex-shrink flex items-center justify-center bg-indigo-500 border border-indigo-500 hover:border-indigo-500 hover:bg-indigo-500 text-white hover:text-white font-semibold rounded shadow-lg shadow-indigo-500/50 hover:shadow-indigo-500/75 focus:outline-none">Update</button>
        </div>
        {
          loaded && transactions.meta.attachment !== '' &&
          <div className="block col-span-5">
            <a className="text-xs text-indigo-500 no-underline hover:underline decoration-wavy decoration-rose-500" target="_blank" href={ transactions.meta.attachment }>Download generated CSV file.</a>
          </div>
        }
      </form>
      {
        loaded &&
        <Transactions transactions={transactions.data} />
      }
    </div>
  )
}

export default TransactionList
