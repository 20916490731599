import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Auth from '../components/Auth'
import Field from '../components/Field'
import HTTP from '../services/http'

export default function Login({ setAuth })  {
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const onSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    await HTTP.post('/login', {
      username: username,
      password: password,
      device_name: navigator.userAgent
    })
      .then((response) => setAuth(JSON.stringify(response.data)))
      .catch((error) => handleErrors(error.response.data))
      // .then(() => console.log('always executed'))
  }

  const handleErrors = (data) => {
    setErrors(data)
    setLoading(false)
  }

  return (
    <Auth
      title="Login to account"
      fields={[
        <Field onChange={event => setUsername(event)} type="text" id="username" name="username" label="Email or Phone Number" />,
        <Field onChange={event => setPassword(event)} type="password" id="password" name="password" label="Password" />
      ]}
      errors={errors}
      onSubmit={onSubmit}
      loading={loading}
      submitLabel="Login"
    />
  )
}

Login.propTypes = {
  setAuth: PropTypes.func.isRequired
}
