import PropTypes from 'prop-types'

export default function MiniCard({
  customClass,
  title,
  body,
  size
}) {
  return (
    <div className={`${customClass} p-6`}>
      <h4 className="mb-2 text-sm font-medium">{title}</h4>
      <p className={`${size === 'small' ? 'text-xs' : 'text-3xl leading-tight'} font-light`}>{body}</p>
    </div>
  )
}

MiniCard.propTypes = {
  customClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
}
