import { Link, Outlet } from 'react-router-dom'
import { DocumentReportIcon, ChartPieIcon, ReceiptTaxIcon, } from '@heroicons/react/outline'
import useAuth from '../hooks/useAuth'
import Login from './Login'
import Navigation from '../components/Navigation'
import UserDropdown from '../components/UserDropdown'
import './App.css'

function App() {
  const {auth, setAuth} = useAuth()

  if(!auth) {
    return <Login setAuth={setAuth} />
  }

  return (
    <div className="grid grid-cols-8 gap-4 lg:gap-6 w-full min-h-screen">
      <aside className="bg-gray-100">
        <div className="relative mt-24 p-6 lg:p-8 lg:pr-2">
          <ul className="static inset-x-0 top-32 block">
            <li className="block text-sm font-light mb-3">
              <Link to='/' className="transition ease-in-out delay-150 p-3 flex w-full items-center rounded-md bg-gray-200 bg-opacity-50 hover:bg-gray-50 text-gray-500 hover:text-indigo-500">
                <ChartPieIcon
                  className="flex-shrink-1 w-5 h-5 mr-3 text-inherit opacity-80"
                  aria-hidden="true"
                />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="block text-sm font-light mb-3">
              <Link to='transactions' className="transition ease-in-out delay-150 p-3 flex w-full items-center rounded-md bg-transparent bg-opacity-50 hover:bg-gray-50 text-gray-500 hover:text-indigo-500">
                <DocumentReportIcon
                  className="flex-shrink-1 w-5 h-5 mr-3 text-inherit opacity-80"
                  aria-hidden="true"
                />
                <span>Transactions</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
      <main className="relative col-span-7 bg-white">
        <Navigation
          className="border-b border-gray-100 w-auto py-4 px-2 lg:px-10"
          user={<UserDropdown />}
        />

        <div className="mt-24 p-4 lg:p-16 pl-0">
          <Outlet />
        </div>
      </main>
    </div>
  )
}

export default App
