import { useEffect, useState } from 'react'

function Field(props) {
  const [fieldValue, setFieldValue] = useState()

  useEffect(() => {
    props.onChange(fieldValue)
  })

  return (
    <div className={props.containerClass || 'w-full mb-6'}>
      <label htmlFor={props.id} className="mb-2 w-full block text-light text-sm">{props.label}</label>
      <input id={props.id} name={props.name} type={props.type} onChange={event => setFieldValue(event.target.value)} className="w-full block form-input p-2 md:p-3 border-gray-200 bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-pink-400 focus-visible:ring-offset-gray-50 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm"/>
    </div>
  );
}

export default Field;
