function User() {
  const auth = localStorage.getItem('auth')
  const user = JSON.parse(auth)
  return user?.user
}

function Token() {
  const auth = localStorage.getItem('auth')
  const token = JSON.parse(auth)
  return token?.token
}

function Client() {
  const auth = localStorage.getItem('auth')
  const client = JSON.parse(auth)
  return client?.client
}

function Balance() {
  const auth = localStorage.getItem('auth')
  const balance = JSON.parse(auth)
  return balance?.balance
}

function Received() {
  const auth = localStorage.getItem('auth')
  const received = JSON.parse(auth)
  return received?.received
}

function Sent() {
  const auth = localStorage.getItem('auth')
  const sent = JSON.parse(auth)
  return sent?.sent
}

function Logout() {
  localStorage.removeItem('auth')
}

export {
  User,
  Client,
  Token,
  Logout,
  Balance,
  Sent,
  Received,
}
