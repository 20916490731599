import Brand from './Brand'
import NavItems from './NavItems'

function Navigation(props) {
  let navItems;

  if (props.items || props.user) {
    navItems = <NavItems items={props.items} user={props.user} />
  }

  return (
    <header className={props.className + ' absolute inset-x-0 top-0 z-10 w-full'}>
      <div className="w-full grid grid-cols-2 px-6 py-3">
        <div>
          <Brand />
        </div>

        {navItems}
      </div>
    </header>
  );
}

export default Navigation;
