import PropTypes from 'prop-types'
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/solid'

export default function Transitions({ transactions }) {
  return (
    <ul className="bg-gray-100 p-4 rounded-lg">
      <li className="grid grid-cols-12 gap-4 font-medium text-xs text-indigo-500 uppercase bg-white rounded-lg mb-3">
        <div className="p-6">Type</div>
        <div className="col-span-3 p-6">Account</div>
        <div className="col-span-2 p-6">Reference №</div>
        <div className="p-6">Status</div>
        <div className="p-6">Charges</div>
        <div className="col-span-2 p-6 text-right">Amount</div>
        <div className="col-span-2 p-6 text-right">Date</div>
      </li>
      {transactions.map(transaction => (
        <li className="grid grid-cols-12 gap-4 text-sm font-light bg-white rounded-lg mb-3" key = { transaction.id } >
          <div className="p-6">
            {transaction.memo === 'DEBIT' &&
              <>
                <span className="sr-only">Received</span>
                <span className="flex items-center justify-center">
                  <span className="bg-teal-100 rounded flex-shrink-1 p-1">
                    <PlusCircleIcon
                      className="block w-4 h-4 text-teal-400 hover:text-teal-500"
                      aria-hidden="true"
                    />
                  </span>
                </span>
              </>
            }
            {transaction.memo === 'CREDIT' &&
              <>
                <span className="sr-only">Sent</span>
                <span className="flex items-center justify-center">
                  <span className="bg-rose-100 rounded flex-shrink-1 p-1">
                    <MinusCircleIcon
                      className="block w-4 h-4 text-rose-400 hover:text-rose-500"
                      aria-hidden="true"
                    />
                  </span>
                </span>
              </>
            }
          </div>
          <div className="col-span-3 p-6">
            {transaction.account_name}
            <div className="text-xs opacity-50">{transaction.account_number}</div>
          </div>
          <div className="col-span-2 p-6">
            <span className="text-xs">
              <span className="block mb-1">{transaction.receipt_id}</span>
              <span className="block opacity-50">ID {transaction.id}</span>
            </span>
          </div>
          <div className="p-6">
            <span className="text-xs">
              <span className="block mb-1">{transaction.status}</span>
            </span>
          </div>
          <div className="p-6 text-right">
            {transaction.status !== 'FAILED' ? transaction.charges : 0}
          </div>
          <div className="col-span-2 text-right p-6">
            {
              transaction.status !== 'FAILED' ?
                new Intl.NumberFormat('en-US').format(transaction.total) :
                new Intl.NumberFormat('en-US').format(transaction.amount)
            }
          </div>
          <div className="col-span-2 text-right p-6">
            {
              new Intl.DateTimeFormat('en-US').format(new Date(transaction.created_at))
            }
          </div>
        </li>
      ))}
    </ul>
  )
}

Transitions.propTypes = {
  transactions: PropTypes.array.isRequired
}
