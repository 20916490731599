import React, { useEffect, useState } from 'react'
import {
  Client,
  Balance,
  Sent,
  Received
} from '../services/user'
import HTTP from '../services/http'

import MiniCard from '../components/MiniCard'
import Transactions from '../components/Transactions'

function Home() {
  const [transactions, setTransactions] = useState()
  const [loaded, setLoaded] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const getTransactions = async () => {
      await HTTP.post('/transactions', {})
        .then((response) => setTransactions(response.data))
        .catch((error) => handleErrors(error.response.data))

      setLoaded(true)
    }
    getTransactions()
  }, [])

  const handleErrors = (data) => {
    setErrors(data)
    setLoaded(false)
  }

  const clientAddress = (
    <>
      {Client().address},
      <br />
      {Client().city}, {Client().country}
    </>
  )
  return (
    <div>
      <div className="mb-12 grid grid-cols-1 lg:grid-cols-4 gap-3 lg:gap-6">
        <MiniCard
          size="small"
          title={Client().name}
          body={clientAddress}
          customClass="bg-gradient-to-r from-pink-500 to-fuchsia-500 text-fuchsia-100 rounded-lg shadow-lg shadow-fuchsia-500/50"
        />
        <MiniCard
          title="Account Balance (USD)"
          body={new Intl.NumberFormat('en-US').format(Balance())}
          customClass="bg-gradient-to-r from-fuchsia-500 to-purple-500 text-purple-100 rounded-lg shadow-lg shadow-purple-500/50"
        />
        <MiniCard
          title="Total Sent (USD)"
          body={new Intl.NumberFormat('en-US').format(Sent())}
          customClass="bg-gradient-to-r from-purple-500 to-violet-500 text-violet-100 rounded-lg shadow-lg shadow-violet-500/50"
        />
        <MiniCard
          title="Total Received (USD)"
          body={new Intl.NumberFormat('en-US').format(Received())}
          customClass="bg-gradient-to-r from-violet-500 to-indigo-600 text-indigo-100 rounded-lg shadow-lg shadow-indigo-500/50"
        />
      </div>
      {
        loaded &&
        <Transactions transactions={transactions.data} />
      }
    </div>
  )
}

export default Home
